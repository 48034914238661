import React, { useState } from 'react'
import moment from 'moment'
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';
import IconButton from '@material-ui/core/IconButton';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import { useEffect } from 'react';
import { fetchEvents, postEvents, putEvents, deleteEvents, unlockEventByMonth, lockEventByMonth, unlockEventByDate, lockEventByDate } from '../business/api'
import { useAuth } from '../business/authProvider';
import SheetRow from '../components/sheet/SheetRow'
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  offset: theme.mixins.toolbar,
}));
const TODAY = moment().add(1, 'months');
const VacationSheet = props => {
  const [events, setEvent] = React.useState({})
  const [maxColumn, setMaxColumn] = useState(0)
  const [locked, setLocked] = React.useState({})
  const [eventYear, setYear] = React.useState(TODAY.year())
  const [eventMonth, setMonth] = React.useState(TODAY.month())
  const classes = useStyles();
  const auth = useAuth();
  const lockMonth = () => {
    let promise;
    if (locked) promise = unlockEventByMonth(eventMonth + 1, eventYear)
    else promise = lockEventByMonth(eventMonth + 1, eventYear)
    promise.then(response => {
      alert(response.message);
      return loadEvents();
    })
  }

  const lockDate = (date) => {
    let promise;
    if (events[date.format('YYYY-MM-DD')].locked) promise = unlockEventByDate(date.format('DD'), eventMonth + 1, eventYear)
    else promise = lockEventByDate(date.format('DD'), eventMonth + 1, eventYear)
    promise.then(response => {
      alert(response.message);
      return loadEvents();
    })
  }

  const prevMonth = () => {
    let date = moment(`${eventYear}-${eventMonth + 1}`, 'YYYY-MM').subtract(1, 'months');
    updateDate(date)
  }
  const nextMonth = () => {
    let date = moment(`${eventYear}-${eventMonth + 1}`, 'YYYY-MM').add(1, 'months');
    updateDate(date)
  }
  const updateDate = (date) => {
    if (date.month() !== eventMonth) setMonth(date.month());
    if (date.year() !== eventYear) setYear(date.year());
  }
  useEffect(() => {
    loadEvents();
  }, [eventMonth, eventYear])

  const singleRequest = (data, method) => {
    let promise = null;
    if (method === "put") promise = putEvents(data)
    else promise = postEvents(data)
    return promise;
  }

  const onRequestOff = ({ replace_id, date, shift_id, covering, coveringPerson, user_id, requestFullWeek }) => {
    if (window.confirm(`Confirm Request?`)) {
      let promise = null;
      if (replace_id) promise = putEvents({ replace_id, shift_id, covering, coveringPerson, user_id, requestFullWeek })
      else if (requestFullWeek) {
        var now = moment(date);
        var startDate = now.clone().weekday(1);
        var endDate = now.clone().weekday(6);
        let dates = [];
        for (startDate; startDate.isBefore(endDate); startDate.add(1, 'days')) {
          dates.push(startDate.format('YYYY-MM-DD'));
        }
        return Promise.all(dates.map(date => postEvents({ date, shift_id, covering, coveringPerson, user_id, requestFullWeek })))
          //.then(json => console.log(json))
          .then(loadEvents)
      } else {
        promise = postEvents({ date: moment(date).format('YYYY-MM-DD'), shift_id, covering, coveringPerson, user_id, requestFullWeek })
      }
      promise.then(json => alert(json.message))
        .then(loadEvents)
        .catch(error => console.log(error))
    }
  }
  const onRequestRemove = (id, date) => {
    if (window.confirm(`Withdraw request?`)) {
      deleteEvents({ id, date: moment(date).format('YYYY-MM-DD') })
        .then(json => alert(json.message))
        .then(loadEvents)
        .catch(error => console.log(error))
    }
  }
  const loadEvents = (response) => {
    fetchEvents(eventMonth + 1, eventYear).then(parseEvents)
  }
  const parseEvents = json => {
    let totalDaysInMonth = moment(`${eventYear}-${eventMonth + 1}`, 'YYYY-MM').daysInMonth();
    let data = JSON.parse(JSON.stringify(json));
    /*for (var i = 0; i < totalDaysInMonth; i++) {
      data[moment(`${eventYear}-${eventMonth + 1}-${i + 1}`, 'YYYY-MM-DD').format('YYYY-MM-DD')] = {
        locked: false,
        data: []
      };
    }*/
    let maxC = 0;
    let datesLocked = 0;
    Object.keys(data).map(date => {
      data[date].data = data[date].data.map((entry, index) => ({ ...entry, after6: index > 5 }))
      maxC = Math.max(maxC, data[date].data.length)
      datesLocked = datesLocked + (data[date].locked ? 1 : 0);
    });
    /*let maxC = 0;
    let datesLocked = 0;
    Object.keys(data).map((event, index) => {
      maxC = Math.max(maxC, data[event].data.length)
      data[event].locked = data[event].data.length ? data[event].data[0].locked : false;
      datesLocked = datesLocked + (data[event].locked ? 1 : 0);
    });
    */
    setMaxColumn(maxC);
    setEvent(data)
    setLocked(datesLocked === totalDaysInMonth)
  }


  return (
    <div className={'sheet-holder'}>
      <div className={'sheet-header'}>
        <div>{moment(`${eventYear}-${eventMonth + 1}`, 'YYYY-MM').format('MMM, YYYY')}</div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {auth.permissions.canLock ? <Button startIcon={locked ? <Lock /> : <LockOpen />} style={{ marginRight: 8 }} variant="outlined" color="inherit" onClick={lockMonth}>{locked ? `Unlock Month` : `Lock Month`}</Button> : null}
          <Button startIcon={<ArrowLeft />} style={{ marginRight: 8 }} variant="outlined" color="inherit" onClick={prevMonth}>Previous Month</Button>
          <Button endIcon={<ArrowRight />} variant="outlined" color="inherit" onClick={nextMonth}>Next Month</Button>
        </div>
      </div>
      <div className={'sheet-header mobile'}>
        <div>{moment(`${eventYear}-${eventMonth + 1}`, 'YYYY-MM').format('MMM, YYYY')}</div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {auth.permissions.canLock ?
            <IconButton onClick={lockMonth} style={{ marginRight: 8 }}>
              {locked ? <Lock /> : <LockOpen />}
            </IconButton>
            : null}
          <IconButton onClick={prevMonth} style={{ marginRight: 8 }}>
            <ArrowLeft />
          </IconButton>
          <IconButton onClick={nextMonth}>
            <ArrowRight />
          </IconButton>
        </div>
      </div>
      <div className={'sheet-wrapper'}>
        <table>
          <tbody>
            {Object.keys(events).map((event, index) => <SheetRow maxColumn={maxColumn} alternateRow onRequestOff={onRequestOff} onRequestRemove={onRequestRemove} onLockDay={lockDate} key={event} date={event} locked={events[event].locked} columns={events[event].data} />)}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default VacationSheet;