import Create from './create';
import List from './list';
import View from './view';
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";
export default ({ match }) => <Switch>
  <Route path={`${match.url}/list`} component={List} />
  <Route path={`${match.url}/create`} component={Create} />
  <Route path={`${match.url}/:id`} component={View} />
  <Route path={match.url} exact={true}>
    <Redirect to={`${match.url}/list`} />
  </Route>
</Switch>
