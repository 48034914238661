import React, { useState } from 'react'
import SheetCell from './SheetCell'
import RequestDialog from './RequestDialog'
import CellUpdateDialog from './CellUpdateDialog'
import CellToolTip from './CellToolTip'
import { useAuth } from '../../business/authProvider';
import moment from 'moment'
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';

let editingCell = null;
const SheetRow = ({ date, columns, onRequestRemove, onRequestOff, maxColumn, locked, onLockDay }) => {
  const [openRequest, openRequestDialog] = useState(false);
  const [openUpdate, openUpdateDialog] = useState(false);
  const [toolTip, showTooltip] = useState(null);
  const auth = useAuth();
  const mdate = moment(date, 'YYYY-MM-DD');
  /**
   * 
   * @returns 
   */
  const handleRequest = () => {
    if (locked) return alert("No other requests are allowed for this day.")
    editingCell = null;
    openRequestDialog(true);
  }
  /**
   * 
   * @returns 
   */
  const closeRequestDialog = () => openRequestDialog(false);
  /**
   * 
   * @param {*} param0 
   * @returns 
   */
  const onRequestSubmit = ({ shift_id, covering, coveringPerson, user_id, shouldRequestFullWeek }) => {
    if (covering && coveringPerson.trim() === "") {
      return alert("Please provide a covering person.")
    }else if(!shift_id){
      return alert("Please select a shift")
    }
    closeRequestDialog();
    onRequestOff({ replace_id: editingCell?.id, date, shift_id, covering, coveringPerson, user_id, requestFullWeek: shouldRequestFullWeek })
  }
  /**
   * 
   * @param {*} id 
   */
  const onClickCell = (id) => {
    if (auth.permissions.canUpdateCell) {
      editingCell = columns.find(column => column.id === id);
      handleUpdate()
    } else {
      onRequestRemove(id, date);
    }
  }
  /**
   * 
   * @param {*} id 
   */
  const onLongPressCell = id => {
    showTooltip(columns.find(column => column.id === id))
  }
  /**
   * 
   * @returns 
   */
  const closeToolTipDialog = () => showTooltip(null)
  /**
   * 
   * @returns 
   */
  const handleUpdate = () => openUpdateDialog(true);
  /**
   * 
   * @returns 
   */
  const closeUpdateDialog = () => { editingCell = null; openUpdateDialog(false); }
  /**
   * 
   * @param {*} id 
   * @returns 
   */
  const onWithdrawRequest = (id) => {
    onRequestRemove(editingCell.id, date);
    closeUpdateDialog()
  }
  const onUpdateRequested = () => {
    openUpdateDialog(false);
    openRequestDialog(true);
  }
  const lockMonth = () => onLockDay(mdate)
  /**
   * 
   */
  let totalColumns = columns.length > 6 ? maxColumn : maxColumn + 1;
  let extraColumns = totalColumns > columns.length ? new Array(totalColumns - columns.length).fill('') : null;
  return (
    <tr className={`sheet-row ${(mdate.day() === 0 || mdate.day() === 6) ? 'weekend' : ""}`}>
      <td>{mdate.format('MM-DD-YYYY')}</td>
      <td>{mdate.format('dddd')}</td>
      {columns.map((column, index) => {
        return <>
          {index === 6 ? <td key={'extra'} className={'blank'} dangerouslySetInnerHTML={{ __html: '&nbsp;' }}></td> : null}
          <SheetCell key={index} onClick={onClickCell} onLongPress={onLongPressCell} {...column} />
        </>
      })}
      {
        extraColumns ? extraColumns.map((row, index) => <td key={index} dangerouslySetInnerHTML={{ __html: '&nbsp;' }}></td>) : null
      }
      <td className={'grow'} dangerouslySetInnerHTML={{ __html: '&nbsp;' }}></td>
      <td style={{ width: 160 }}><Button startIcon={<AddIcon />} style={{ fontSize: 11, paddingTop: 1, paddingBottom: 1, paddingLeft: 4, paddingRight: 4 }} variant={'contained'} onClick={handleRequest}>MAKE A REQUEST</Button></td>
      {auth.permissions.canLock ?
        <td style={{ width: 54 }}>
          <IconButton onClick={lockMonth}>
            {locked ? <Lock /> : <LockOpen />}
          </IconButton>
        </td>
        : null}
      {openRequest ? <RequestDialog editingCell={editingCell} date={date} onSubmit={onRequestSubmit} onCancel={closeRequestDialog} /> : null}
      {openUpdate ? <CellUpdateDialog onWithdrawRequest={onWithdrawRequest} onSubmit={onUpdateRequested} onCancel={closeUpdateDialog} /> : null}
      {toolTip ? <CellToolTip data={toolTip} onCancel={closeToolTipDialog} /> : null}
    </tr>
  )
}

export default SheetRow