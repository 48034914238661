import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    userSelect: 'none'
  },
});

const CellUpdateDialog = ({
  onCancel,
  data
}) => {
  const classes = useStyles();
  return (
    <Dialog maxWidth={'sm'} fullWidth open={true} onClose={onCancel} aria-labelledby="form-dialog-title">
      <DialogContent className={classes.root}>
        {data.label}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default CellUpdateDialog