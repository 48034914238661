import React from 'react'
import 'react-big-calendar/lib/sass/styles.scss'
import './App.css';
import AppBar from './containers/appBar'
import { ToastContainer, toast } from 'react-toastify';
import LoginPage from './components/signIn'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { ProvideAuth, useAuth } from './business/authProvider'
import 'react-toastify/dist/ReactToastify.css';
function App(props) {
  const { onLogout, onUpdatePassword } = props
  return (
    <div style={{ width: '100%' }}>
      <Router>
        <ProvideAuth>
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <PrivateRoute path="/">
              <Router>
                <AppBar />
              </Router>
            </PrivateRoute>
          </Switch>
        </ProvideAuth>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </div>
  );
}

export default App;

function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}