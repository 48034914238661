import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Modal } from '@material-ui/core';
import { useAuth } from '../business/authProvider';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 300
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
function SignIn() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const classes = useStyles();
  let auth = useAuth();
  const onChange = e => {
    switch (e.target.id) {
      case "email": return setEmail(e.target.value)
      case "password": return setPassword(e.target.value)
    }
  }
  const authenticate = (e) => {
    e.preventDefault();
    auth.signin(email, password).catch(error => alert(error.message));
    return false
  }
  if (auth.busy) return <div className={'preloader-wrapper'}>
    <CircularProgress />
  </div>;
  return (
    <div className={'login-wrapper'}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form onSubmit={authenticate} className={classes.form} noValidate>
          <TextField
            onChange={onChange}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoFocus
          />
          <TextField
            onChange={onChange}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
        </form>
      </div>
    </div>
  );
}

export default SignIn