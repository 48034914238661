import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, CircularProgress } from '@material-ui/core';
import { fetchUsers } from '../../../business/api'
import { Link } from 'react-router-dom';
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function BasicTable() {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [busy, setBusy] = useState(false);
  useEffect(() => {
    fetchUsers(true).then(setUsers).then(result => setBusy(false))
  }, [])
  return (busy ?
    <CircularProgress />
    :
    <div>
      <div style={{ padding: 12, display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
        <span>Users</span>
        <Button variant="outlined" color="inherit" component={Link} to={'/users/create'}>Create</Button>
      </div>
      <div style={{ padding: 12 }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: 165 }}>Name</TableCell>
                <TableCell style={{ minWidth: 90 }} align="left">Short Name</TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left" style={{ minWidth: 125 }}>Cell</TableCell>
                <TableCell align="left" style={{ minWidth: 125 }}>Pager</TableCell>
                <TableCell align="left">Active</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.short_name}</TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="left">{row.cell}</TableCell>
                  <TableCell align="left">{row.pager}</TableCell>
                  <TableCell align="left">{row.is_active === 1 ? "Active" : "Suspended"}</TableCell>
                  <TableCell align="right">
                    <Button variant="contained" component={Link} to={`/users/${row.user_id}`}>Edit</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>)
}