import React, { useEffect, useState } from 'react'
import { Form, Field } from 'react-final-form';
import { TextField, Checkbox, Radio, Select } from 'final-form-material-ui';
import {
  Typography,
  Paper,
  Switch,
  Grid,
  Button,
  CssBaseline,
  RadioGroup,
  FormLabel,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
import { fetchNote, fetchNoteReplies, createNoteReply } from '../../../business/api'
import { toast } from 'react-toastify';
import moment from 'moment';
const validate = values => {
  const errors = {};
  if (!values.message) {
    errors.message = 'Required';
  }
  return errors;
};


export default ({ match, history }) => {
  const [note, setNote] = useState(null)
  const [data, setData] = useState([])
  const [noteId, setNoteId] = useState(match.params.id)
  useEffect(() => {
    fetchNote(noteId).then(setNote);
    fetchNoteReplies(noteId).then(setData);
  }, [noteId])
  const onSubmit = (values) => {
    createNoteReply(noteId, values).then(response => {
      fetchNoteReplies(noteId).then(setData);
      toast(response.message);
    })
  }
  return (
    <div style={{ padding: 12, width: '100%' }}>
      <CssBaseline />
      <Typography variant="h6" id="tableTitle" component="div" gutterBottom>
        {note?.message}
      </Typography>
      <Typography variant="h6" id="tableTitle" component="div" gutterBottom>
        Replies
        </Typography>
      {data.length ? data.map(row => <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ padding: "12px 12px 12px 0px" }}><strong>{row.name}</strong></div>
        <div style={{ flex: 1, padding: 12 }}>{row.message}</div>
        {moment(row.created_at).format('DD/MM')}
      </div>) : ''}
      <div style={{ height: '100%', width: '100%', maxWidth: 600, }}>
        <div style={{ margin: 'auto' }}>
          <Typography variant="h6" id="tableTitle" component="div" gutterBottom>
            Add new reply
        </Typography>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              message: ''
            }}
            validate={validate}
            render={({ handleSubmit, reset, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Paper style={{ padding: 16 }}>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                      <Field name="message" fullWidth required multiline component={TextField} type="text" label="Message" />
                    </Grid>
                    <Grid item style={{ marginTop: 16 }}>
                      <Button type="button" variant="contained" onClick={reset} disabled={submitting || pristine}>
                        Reset
                  </Button>
                    </Grid>
                    <Grid item style={{ marginTop: 16 }}>
                      <Button variant="contained" color="primary" type="submit" disabled={submitting}>
                        Submit
                    </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </form>
            )}
          />
        </div>
      </div>
    </div>
  )
}

const MaterialSwitch = ({ label, input }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          {...input}
          color="primary"
        />
      }
      label={label}
    />
  )
}