import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core'
import React from 'react'
const CellUpdateDialog = ({
  onCancel,
  onSubmit,
  onWithdrawRequest
}) => {
  return (
    <Dialog maxWidth={'sm'} fullWidth open={true} onClose={onCancel} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">What would you like to do?</DialogTitle>
      <DialogActions>
        <Button onClick={onCancel} color="primary">Cancel</Button>
        <Button onClick={onSubmit} color="primary">Update Cell</Button>
        <Button onClick={onWithdrawRequest} color="primary">Withdraw Request</Button>
      </DialogActions>
    </Dialog>
  )
}

export default CellUpdateDialog