import React, { useEffect, useState } from 'react'
import { Form, Field } from 'react-final-form';
import { TextField, Checkbox, Radio, Select } from 'final-form-material-ui';
import {
  Typography,
  Paper,
  Switch,
  Grid,
  Button,
  CssBaseline,
  RadioGroup,
  FormLabel,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
import { createNotes } from '../../../business/api'
import { toast } from 'react-toastify';
const validate = values => {
  const errors = {};
  if (!values.message) {
    errors.message = 'Required';
  }
  return errors;
};


export default ({ history }) => {
  const onSubmit = (values) => {
    console.log(values)
    createNotes(values).then(response => {
      toast(response.message);
      history.goBack();
    })
  }
  return (
    <div style={{ padding: 12, height: '100%', width: '100%', maxWidth: 600, }}>
      <div style={{ margin: 'auto' }}>
        <CssBaseline />
        <Typography variant="h6" id="tableTitle" component="div" gutterBottom>
          Add new note
      </Typography>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            message: ''
          }}
          validate={validate}
          render={({ handleSubmit, reset, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Paper style={{ padding: 16 }}>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Field name="message" fullWidth required multiline component={TextField} type="text" label="Message" />
                  </Grid>
                  <Grid item style={{ marginTop: 16 }}>
                    <Button type="button" variant="contained" onClick={reset} disabled={submitting || pristine}>
                      Reset
                  </Button>
                  </Grid>
                  <Grid item style={{ marginTop: 16 }}>
                    <Button variant="contained" color="primary" type="submit" disabled={submitting}>
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          )}
        />
      </div>
    </div>
  )
}

const MaterialSwitch = ({ label, input }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          {...input}
          color="primary"
        />
      }
      label={label}
    />
  )
}