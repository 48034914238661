import React from 'react'
import useLongPress from '../../business/useLongPress';

const SheetCell = ({
  removable,
  is_active,
  after6,
  onClick,
  onLongPress,
  label,
  id
}) => {
  const _onLongPress = () => onLongPress(id);
  const backspaceLongPress = useLongPress(_onLongPress, 500);
  const handleClick = () => {
    if (removable) { onClick(id); }
  }
  let cellStyle = [];
  if (removable) cellStyle.push('pointer');
  if (after6) cellStyle.push('after6');
  if (!is_active) cellStyle.push('line-through');
  return (
    <td title={label} onClick={handleClick} {...backspaceLongPress} className={cellStyle.join(" ")}>{label}</td>)
}

export default SheetCell