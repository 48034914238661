import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, CircularProgress } from '@material-ui/core';
import { fetchNotes, archiveNotes } from '../../../business/api'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function BasicTable() {
  const classes = useStyles();
  const [notes, setNotes] = useState([]);
  const [busy, setBusy] = useState(false);
  useEffect(() => {
    reloadNotes();
  }, []);
  const reloadNotes = () => fetchNotes().then(setNotes).then(result => setBusy(false))
  return (busy ?
    <CircularProgress />
    :
    <div>
      <div style={{ padding: 12, display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
        <span>Notes</span>
        <Button variant="outlined" color="inherit" component={Link} to={'/notes/create'}>Create</Button>
      </div>
      <div style={{ padding: 12 }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width="150">Name</TableCell>
                <TableCell align="left">Message</TableCell>
                <TableCell align="center" width="180"></TableCell>
                <TableCell align="right" width="120"></TableCell>
                <TableCell align="right" width="120"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notes.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">{row.name}</TableCell>
                  <TableCell align="left">{row.message}</TableCell>
                  <TableCell align="center">{row.total_messages > 0 ? 'Reply has been made' : '--'}</TableCell>
                  <TableCell align="right">
                    <Button variant="contained" component={Link} to={`/notes/${row.note_id}`}>Reply</Button>
                  </TableCell>
                  <TableCell align="right">
                    <Button variant="contained" onClick={
                      () => archiveNotes(row.note_id)
                        .then(response => {
                          toast(response.message);
                          reloadNotes()
                        })
                    }>Archived</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>)
}