import { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { checkAuth, signInUser, getPermissions, logout, updateUserPassword } from './api';

const authContext = createContext();

function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

function useAuth() {
  return useContext(authContext);
}

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [permissions, setPermissions] = useState({});
  const [busy, setBusy] = useState(false)
  let history = useHistory();
  useEffect(() => { checkLoginStatus() }, [])

  const checkLoginStatus = () => {
    setBusy(true)
    checkAuth()
      .then(response => {
        if (response.status === 200) {
          getUserPermission().then(response => {
            setUser({ isAuthenticated: true });
            setBusy(false)
            history.replace("/");
          })
        } else {
          return setBusy(false);
        }
      })
  }

  const signin = (e, p) => {
    setBusy(true)
    return signInUser(e, p)
      .then((response) => {
        return new Promise((resolve) => response.json()
          .then((json) => resolve({
            status: response.status,
            ok: response.ok,
            json,
          })));
      })
      .then(({ status, json, ok }) => {
        if (status >= 400 && status < 600) {
          setBusy(false)
          throw new Error(json.message)
        } else {
          getUserPermission().then(response => {
            setUser({ isAuthenticated: true });
            setBusy(false)
            history.replace("/");
          })
        }
      })
  }
  const getUserPermission = () => getPermissions().then(response => response.json()).then(setPermissions)
  const signout = () => logout().then(() => { setUser(null); history.push('/') });
  const updatePassword = (p1, p2) => {
    setBusy(true)
    updateUserPassword(p1, p2)
      .then(response => {
        setBusy(false)
        if (response.status >= 400 && response.status < 600) {
          throw new Error(response);
        }
        signout()
        alert('Password updated successfully. Please relogin with new password.')
      })
      .catch(error => console.log(error.message))
  }
  return {
    user,
    busy,
    permissions,
    signin,
    signout,
    updatePassword
  };
}

export {
  ProvideAuth,
  useAuth
}