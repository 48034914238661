import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router';
import { logout } from '../business/api';
import { useAuth } from '../business/authProvider';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 300
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const API = process.env.REACT_APP_API_END_POINT;
function UpdatePassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("")
  const classes = useStyles();
  let auth = useAuth();
  const onChange = e => {
    switch (e.target.id) {
      case "confirm-password": return setConfirmPassword(e.target.value)
      case "password": return setPassword(e.target.value)
    }
  }
  const onSubmit = (e) => {
    //
    e.preventDefault();
    if (confirmPassword.trim() !== password.trim()) {
      return alert('Confirm password doesnt match.');
    }
    auth.updatePassword(password, confirmPassword)
    return false
  }
  return (
    <div className={'login-wrapper'}>
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Update Password
        </Typography>
        <form onSubmit={onSubmit} className={classes.form} noValidate>
          <TextField
            onChange={onChange}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="New Password"
            type="password"
            autoFocus
          />
          <TextField
            onChange={onChange}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Confirm Password"
            type="password"
            id="confirm-password"
          />
          {auth.busy ?
            <CircularProgress /> : <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Update
            </Button>
          }
        </form>
      </div>
    </div>
  );
}

export default UpdatePassword