import React, { useEffect, useState } from 'react'
import { Form, Field } from 'react-final-form';
import { TextField, Checkbox, Radio, Select } from 'final-form-material-ui';
import {
  Typography,
  Paper,
  Switch,
  Grid,
  Button,
  Divider,
  CssBaseline,
  RadioGroup,
  FormLabel,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
import { fetchUserById, createUser, putUser } from '../../../business/api'

const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  }
  if (!values.name) {
    errors.name = 'Required';
  }
  if (!values.short_name) {
    errors.short_name = 'Required';
  }
  return errors;
};


export default ({ match, history }) => {
  const [data, setUser] = useState(null)
  let edit = match?.params?.id || false;
  useEffect(() => {
    if (edit) fetchUserById(edit).then(setUser)
  }, [])
  const onSubmit = (values) => {
    if (edit) putUser(edit, values).then(response => console.log(response))
    else createUser(values).then(response => {
      alert(`Password is ${response.password}`)
      history.goBack();
    })
  }
  return (
    <div style={{ maxWidth: 600, }}>
      <Paper>
        <div style={{ padding: 16 }}>
          <Typography variant="body2" component="div">
            {edit ? 'Edit' : 'Create'} User
          </Typography>
        </div>
        <Divider />
        <Form
          onSubmit={onSubmit}
          initialValues={{
            email: '',
            password: '',
            name: '',
            short_name: '',
            home_number: '',
            cell: '',
            pager: '',
            is_active: false,
            ...data
          }}
          validate={validate}
          render={({ handleSubmit, reset, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Paper style={{ padding: 16 }}>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Field name="email" fullWidth required component={TextField} type="email" label="Email" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="password" fullWidth required component={TextField} type="text" label="Password" />
                    <div>{edit ? 'Leave blank if you dont want to change it.' : 'Leave blank if you want to auto generate password'}</div>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="name" fullWidth required component={TextField} type="text" label="Name" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="short_name" fullWidth required component={TextField} type="text" label="Short Name" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="cell" fullWidth required component={TextField} type="text" label="Cell" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="home_number" fullWidth required component={TextField} type="text" label="Home Number" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="pager" fullWidth required component={TextField} type="text" label="Pager" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="is_active" label="Active"
                      type="checkbox" fullWidth required
                      component={MaterialSwitch} />
                  </Grid>
                  <Grid item style={{ marginTop: 16 }}>
                    <Button type="button" variant="contained" onClick={reset} disabled={submitting || pristine}>
                      Reset
                    </Button>
                  </Grid>
                  <Grid item style={{ marginTop: 16 }}>
                    <Button variant="contained" color="primary" type="submit" disabled={submitting}>
                      {edit ? 'Update' : 'Submit'}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          )}
        />
      </Paper>
    </div >
  )
}

const MaterialSwitch = ({ label, input }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          {...input}
          color="primary"
        />
      }
      label={label}
    />
  )
}