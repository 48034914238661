const API = process.env.REACT_APP_API_END_POINT;
let options = {
  credentials: 'include',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
}
const responseJson = response => response.json();
export const fetchShifts = () => fetch(`${API}/shifts`, options).then(responseJson)
/**
 * 
 * @param {*} all 
 * @returns 
 */
export const fetchUsers = (all = false) => fetch(`${API}/users?all=${all}`, options).then(responseJson)
/**
 * 
 * @param {*} id 
 * @returns 
 */
export const fetchUserById = (id) => fetch(`${API}/users/${id}`, options).then(responseJson)
/**
 * 
 * @param {*} id 
 * @param {*} values 
 * @returns 
 */
export const putUser = (id, values) => fetch(`${API}/users/${id}`, { ...options, method: "PUT", body: JSON.stringify(values) }).then(responseJson)
/**
 * 
 * @param {*} values 
 * @returns 
 */
export const createUser = (values) => fetch(`${API}/users`, { ...options, method: "POST", body: JSON.stringify(values) }).then(responseJson)
/**
 * 
 * @param {*} eventMonth 
 * @param {*} eventYear 
 * @returns 
 */
export const fetchEvents = (eventMonth, eventYear) => fetch(`${API}/events/${eventYear}/${eventMonth}`, options).then(responseJson)
export const lockEventByMonth = (eventMonth, eventYear) => fetch(`${API}/events/${eventYear}/${eventMonth}/lock`, { ...options, method: 'POST' }).then(responseJson)
export const unlockEventByMonth = (eventMonth, eventYear) => fetch(`${API}/events/${eventYear}/${eventMonth}/unlock`, { ...options, method: 'POST' }).then(responseJson)
export const lockEventByDate = (eventDate, eventMonth, eventYear) => fetch(`${API}/events/${eventYear}/${eventMonth}/${eventDate}/lock`, { ...options, method: 'POST' }).then(responseJson)
export const unlockEventByDate = (eventDate, eventMonth, eventYear) => fetch(`${API}/events/${eventYear}/${eventMonth}/${eventDate}/unlock`, { ...options, method: 'POST' }).then(responseJson)
export const postEvents = (body) => fetch(`${API}/user/events`, { ...options, method: 'POST', body: JSON.stringify(body) }).then(responseJson);
export const putEvents = (body) => fetch(`${API}/user/events`, { ...options, method: 'PUT', body: JSON.stringify(body) }).then(responseJson);
export const deleteEvents = (body) => fetch(`${API}/user/events`, { ...options, method: 'DELETE', body: JSON.stringify(body) }).then(responseJson);
export const checkAuth = () => fetch(`${API}/authenticated`, options)
export const signInUser = (e, p) => {
  let formBody = [];
  formBody.push(encodeURIComponent('email') + "=" + encodeURIComponent(e));
  formBody.push(encodeURIComponent('password') + "=" + encodeURIComponent(p));
  return fetch(`${API}/authenticate`, {
    method: 'POST',
    body: formBody.join("&"),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;'
    },
  })
}
export const getPermissions = () => fetch(`${API}/permissions`, { credentials: 'include', })
export const logout = () => fetch(`${API}/logout`, options).then(responseJson)
export const updateUserPassword = (p1, p2) => {
  let formBody = [];
  formBody.push(encodeURIComponent('confirmPassword') + "=" + encodeURIComponent(p2));
  formBody.push(encodeURIComponent('password') + "=" + encodeURIComponent(p1));
  return fetch(`${API}/updatePassword`, {
    method: 'POST',
    body: formBody.join("&"),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;'
    },
  })
}


export const fetchNotes = () => fetch(`${API}/notes`, options).then(responseJson)
export const fetchNote = (id) => fetch(`${API}/notes/${id}`, options).then(responseJson)
export const fetchNoteReplies = (id) => fetch(`${API}/notes/${id}/replies`, options).then(responseJson)
export const createNotes = (values) => fetch(`${API}/notes`, { ...options, method: "POST", body: JSON.stringify(values) }).then(responseJson);
export const createNoteReply = (id, values) => fetch(`${API}/notes/${id}/replies`, { ...options, method: "POST", body: JSON.stringify(values) }).then(responseJson);
export const archiveNotes = (id) => fetch(`${API}/notes/${id}`, { ...options, method: "DELETE" }).then(responseJson)