import React, { useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  CircularProgress, Dialog, DialogActions,
  DialogContent, DialogTitle, FormControl,
  FormControlLabel,
  FormHelperText, Grid, Input, makeStyles, MenuItem, Select
} from '@material-ui/core';
import moment from 'moment'
import { fetchShifts, fetchUsers } from '../../business/api'
import { useAuth } from '../../business/authProvider';


const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  offset: theme.mixins.toolbar,
}));



const RequestDialog = ({
  onSubmit,
  onCancel,
  date,
  editingCell
}) => {
  let auth = useAuth();
  const classes = useStyles();
  /**
   * 
   */
  const [busy, setBusy] = useState(false);
  /**
   * SHIFT STATE
   */
  const [shift_id, setShift] = useState(null);
  /**
   * Shifts
   */
  const [shifts, setShifts] = useState([]);
  /**
   * USER STATE
   */
  const [user_id, setUser] = useState(null);
  /**
   * users
   */
  const [users, setUsers] = useState([]);
  /**
   * 
   */
  const [covering, setCovering] = useState(false);
  /**
   * 
   */
  const [coveringPerson, setCoveringPerson] = useState("");
  const [shouldRequestFullWeek, setShouldRequestFullWeek] = useState(false);
  /**
  * 
  * @param {*} event 
  * @returns 
  * call back from user selection dropdown
  */
  const handleChangeUser = event => setUser(event.target.value)
  /**
   * 
   * @param {*} event 
   * @returns 
   * callback from shift selection dropdown
   */
  const handleChangeShift = event => setShift(event.target.value);
  /**
   * 
   */
  useEffect(() => {
    if (shifts.length === 0 && !busy) {
      setBusy(true)
      fetchShifts().then(setShifts).then(() => {
        if (auth.permissions) {
          if (auth.permissions.user?.list) {
            return fetchUsers().then(setUsers).then(result => setBusy(false))
          }
        }
        return setBusy(false);
      })
    }
  }, [])
  /**
   * 
   * @param {*} event 
   */
  const onCheckedCovering = event => { setCoveringPerson(""); setCovering(event.target.checked); }
  const onCheckedFullWeek = event => { setShouldRequestFullWeek(event.target.checked); }
  /**
   * 
   * @param {*} event 
   * @returns 
   */
  const onChangeCoveringPerson = event => setCoveringPerson(event.target.value);
  /**
   * 
   */
  const handleSubmit = () => {
    onSubmit({ shift_id, covering, coveringPerson, user_id, shouldRequestFullWeek });
  }
  return (
    <Dialog maxWidth={'sm'} fullWidth open={true} onClose={onCancel} aria-labelledby="form-dialog-title">
      {
        busy ?
          <CircularProgress />
          :
          <>
            <DialogTitle id="form-dialog-title">{
              editingCell ? `Replace a request of ${editingCell.label} for` : 'Make a Request for'}
              {` ${moment(date).format('DD MMM, YYYY')}.`}</DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={12}>
                  {users.length ?
                    <FormControl className={classes.formControl} fullWidth>
                      <Select
                        id="demo-simple-select-outlined"
                        value={user_id}
                        onChange={handleChangeUser}
                        label="User"
                        fullWidth
                        placeholder="Select a user"
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        {users.map(entry => <MenuItem key={entry.short_name} value={entry.user_id}>{entry.short_name}</MenuItem>)}
                      </Select>
                      <FormHelperText>Select a User</FormHelperText>
                    </FormControl>
                    :
                    null
                  }
                  <FormControl className={classes.formControl} fullWidth>
                    <Select
                      id="demo-simple-select-outlined"
                      value={shift_id}
                      onChange={handleChangeShift}
                      label="Shift"
                      displayEmpty
                      fullWidth
                      renderValue={value => value === null ? "" : shifts.find(shift => shift.shift_id === value).label}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {shifts.map(entry => <MenuItem key={entry.label} value={entry.shift_id}>{entry.label}</MenuItem>)}
                    </Select>
                    <FormHelperText>Select a Shift</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={<Checkbox checked={covering} onChange={onCheckedCovering} name="checkedA" />}
                      label="Being Covered By"
                    />
                  </Grid>
                  {covering ? <Grid item xs={6}> <Input onChange={onChangeCoveringPerson} placeholder={'Who?'} inputProps={{ 'aria-label': 'description' }} /></Grid> : null}
                </Grid>
              </Grid>
              {auth.permissions.canRequestFullWeek && !editingCell ?
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={<Checkbox checked={shouldRequestFullWeek} onChange={onCheckedFullWeek} name="checkedB" />}
                        label="Request Full Week"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                : null}
            </DialogContent>
            <DialogActions>
              <Button onClick={onCancel} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="primary">
                Send Request
              </Button>
            </DialogActions>
          </>
      }
    </Dialog>
  )
}

export default RequestDialog